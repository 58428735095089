import _ajv from "ajv";
import _error from "./error";
import _harSchema from "har-schema";
import _jsonSchemaDraft from "ajv/lib/refs/json-schema-draft-06.json";
var exports = {};
var Ajv = _ajv;
var HARError = _error;
var schemas = _harSchema;
var ajv;

function createAjvInstance() {
  var ajv = new Ajv({
    allErrors: true
  });
  ajv.addMetaSchema(_jsonSchemaDraft);
  ajv.addSchema(schemas);
  return ajv;
}

function validate(name, data) {
  data = data || {}; // validator config

  ajv = ajv || createAjvInstance();
  var validate = ajv.getSchema(name + ".json");
  return new Promise(function (resolve, reject) {
    var valid = validate(data);
    !valid ? reject(new HARError(validate.errors)) : resolve(data);
  });
}

exports.afterRequest = function (data) {
  return validate("afterRequest", data);
};

exports.beforeRequest = function (data) {
  return validate("beforeRequest", data);
};

exports.browser = function (data) {
  return validate("browser", data);
};

exports.cache = function (data) {
  return validate("cache", data);
};

exports.content = function (data) {
  return validate("content", data);
};

exports.cookie = function (data) {
  return validate("cookie", data);
};

exports.creator = function (data) {
  return validate("creator", data);
};

exports.entry = function (data) {
  return validate("entry", data);
};

exports.har = function (data) {
  return validate("har", data);
};

exports.header = function (data) {
  return validate("header", data);
};

exports.log = function (data) {
  return validate("log", data);
};

exports.page = function (data) {
  return validate("page", data);
};

exports.pageTimings = function (data) {
  return validate("pageTimings", data);
};

exports.postData = function (data) {
  return validate("postData", data);
};

exports.query = function (data) {
  return validate("query", data);
};

exports.request = function (data) {
  return validate("request", data);
};

exports.response = function (data) {
  return validate("response", data);
};

exports.timings = function (data) {
  return validate("timings", data);
};

export default exports;
export const afterRequest = exports.afterRequest,
      beforeRequest = exports.beforeRequest,
      browser = exports.browser,
      cache = exports.cache,
      content = exports.content,
      cookie = exports.cookie,
      creator = exports.creator,
      entry = exports.entry,
      har = exports.har,
      header = exports.header,
      log = exports.log,
      page = exports.page,
      pageTimings = exports.pageTimings,
      postData = exports.postData,
      query = exports.query,
      request = exports.request,
      response = exports.response,
      timings = exports.timings;